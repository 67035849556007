.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: space-between;
    margin-right: 40px;
}

.waving_hand {
  height: 3.5rem;
  pointer-events: none;
  position: relative;
  left: 0.5rem;
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: black;
}

.Introduction {   
  color: black;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 3rem;
  text-align: left;
}

.Name{
  justify-content: start;
  color: black;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 2.5rem;
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: left;
  max-width: 550px;
  letter-spacing: -.02rem;
}

.AboutMe{
  justify-content: start;
  color: black;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
  text-align: left;
  max-width: 550px;
  letter-spacing: -.02rem;
  transition: 0.2s;
  text-decoration: none;
}
  
.AboutMe:hover{
    color: grey;
}


body {
  background-color: white;
}

.Intro-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.my_image {
  width: 360px;
  margin-left: 40px;
}

.flip-card {
  background-color: transparent;
  width: 360px;
  height: 360px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}


.flip-card-back {
  transform: rotateY(180deg);
}



@media (max-width: 1080px) {
  .App {
      margin-right: 0px;
      margin-bottom: 50px;
  }

  .Intro-items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 10%;
      padding-right: 10%;
  }

  .AboutMe{
      margin-bottom: 0px;
  }
  .my_image {
    margin-top: 0px;
    margin-left: 0px;
  }
}




@media (max-width: 600px){
  .Intro-items{
    padding-top: 40px;
  }
  .Name{
    padding-top: 20px;
  }
  .my_image{
    margin-top: 20px;
  }
}


