.myImage{
    width: 360px;
    height: 360px;
    margin-right: 40px;
}

.More-About{
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.About-Icon{
    margin-left: 7px;
    height: 56px;
}

.about-items{
    margin-top: 100px;
    padding-right: 15%;
    padding-left: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.information{
    font-family: 'Roboto', sans-serif;
    max-width: 50%;
    margin-left: 40px;
}
h2{
    font-size: 36px;
    margin: 0px;
}

p{
    font-size: 24px;
    margin: 0px;
    margin-top: 10px;
}

@media (max-width: 1080px) {
    .myImage {
        margin-right: 0px;
    }

    .about-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10%;
        padding-right: 10%;
    }

    .information {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        margin-left: 0px;
        text-align: center;
        max-width: 100%;
    }

    p {
        font-size: 20px;
    }
  }

@media (max-width: 600px) {
    .about-items{
        margin-top: 60px;
    }
    
    .information{
        margin-top: 30px;
    }
}
  