.Connect{
    text-align: center;
    color: black;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    margin-bottom: 50px;
    margin-top: 120px;
}

.Connect-items > a > img {
    width: 30px;
    padding:5px;
    transition: 0.5s;
}

.Connect-items > a {
    text-decoration: none;
}

.Connect-items {
    margin-top: 10px;
}

.Connect > p{
    font-size: 16px;
}