.nav, .list-items {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
}

.nav {
    justify-content: space-between;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 30px;
}

.list-items > a {
    padding: 0px 15px;
    transition: 0.2s;
    text-decoration: none;
    color: black
}

.list-items > a:hover {
    color: grey;
} 

.my-name {
    transition: 0.2s;
    text-decoration: none;
    color: black;
}

.my-name:hover {
    color: grey;
}


.list-items{
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

@media (max-width: 1080px) {
    .nav{
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media (max-width: 600px){
    .nav{
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
    }

    .my-name{
        margin-top: 5px;
        margin-bottom: 10px;
        padding: 10px;
        font-size: 30px;
        color: black;
    }
    .list-items > a {
        color: black;
    }
}