.myskill > img {
    width: 500px;
}

.myskill {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

@media (max-width: 600px) {
    .myskill > img {
        width: 360px;
    }
    .myskill{
        margin-top: 20px;
        margin-bottom: 20px;

    }
    
}