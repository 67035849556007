
.Experience-Header{
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: 40px;
    margin-bottom: 30px;
}

.Experience-Highlight{
    padding-right: 15px;
}

.Experience{
    margin-top: 100px;
    font-family: 'Roboto', sans-serif;
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Wrench-Logo{
    height: 56px;
}



.Collapsible__contentInner {
    max-width: 1000px;
    padding: 10px;
    border-top: 0;
    padding-bottom: 40px;
}
.Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 20px;
    
}
.Collapsible__contentInner p:last-child {
    margin-bottom: 0;
}
.Collapsible__trigger {
    display: block;
    font-size: 28px;
    font-weight: 400;
    text-decoration: none;
    position: relative;
    padding: 10px;
    color: black;
}
.Collapsible__trigger:after {
    font-family: 'FontAwesome';
    content: '\25be';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
}

.Collapsible__trigger:hover {
    color: grey;
    transition: 0.2s;
}

.Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg);
}
.Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
}
.CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease;
}
.CustomTriggerCSS--open {
    background-color: darkslateblue;
}
.Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #cbb700;
    color: black;
}
