.ProjectCard > img{
    width: 300px;
    margin: 10px 10px;
    height: 222px;
    border: solid 1px #ccc;
}

.ProjectCard{
    margin: 15px;
    display: flex column;
    font-family: 'Roboto', sans-serif;
    background-color: white;
    width: 320px;
    height: 420px;
    color: black;
    transition: .2s;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.CardDescription, .CardGithub{
    margin: 0px;
    margin-left: 15px;
    margin-right: 15px;
    overflow: scroll;
}

.CardName{
    padding-top: 20px;
    text-align: center;
    margin: 0px;
}

.CardDescription{
    height: 75px;
    font-size: 14px;
    margin-bottom: 10px;
}

.CardGithub{
    text-align: right;
    font-size: 14px;
    background-color: green;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 59%;
    transition: 0.2s;
    text-decoration: none;
    color: white;
}

.CardGithub:hover{
    background-color: #585858;

}

.Projects{
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.Project{
    margin-top: 100px;
    font-family: 'Roboto', sans-serif;
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Project-Highlight{
    padding-right: 15px;
}

.Project-Highlight-Logo{
    height: 56px;
}

.Project-Header{
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: 40px;
    margin-bottom: 30px;
}



@media (max-width: 1080px){
    .Project{
        padding-left: 10%;
        padding-right: 10%;
    }
}
  
@media (max-width: 1600px){
    .Projects{
        display: grid;
        grid-template-columns: auto auto auto;
    }
}

@media (max-width: 1400px){
    .Projects{
        display: grid;
        grid-template-columns: auto auto;
    }
}

@media (max-width: 800px){
    .Projects{
        display: grid;
        grid-template-columns: auto;
        
    }
}

@media (max-width: 600px) {
    .Project{
        margin-top: 40px;
    }
    .Project-Header{
        margin-bottom: 10px;
    }
}